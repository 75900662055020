<template>
  <div :class="{'contact-container': true, 'calc': true}">
    <div :class="{'contact-description': true, 'description-calc': true}">
      <transition name="fade">
        <a class="m-auto ksu-contact-link" v-if="contactsShow" href="mailto:kseniya.kawko@tonmeister.de">kseniya.kawko@tonmeister.de</a>
      </transition>
<!--      <transition name="fade">-->
<!--        <a v-if="contactsShow" href="tel:+4917632161791" class="ksu-contact-link mb-auto">+49 17632161791 </a>-->
<!--      </transition>-->
    </div>
    <div class="contact-img">
      <!--      <img class="img-fluid" src="../assets/Contact-New.jpg" alt="">-->
    </div>
  </div>


  <!--  </div>-->
</template>

<script>
// import vueCustomScrollbar from 'vue-custom-scrollbar'
// import "vue-custom-scrollbar/dist/vueScrollbar.css"
// import Plane from 'vue-loading-spinner/src/components/Plane'

export default {
  name: "Contact",
  components: {
    // vueCustomScrollbar,
    // Plane
  },
  data() {
    return {
      imageLoading: true,
      percentage: 0,
      breakPoint: 768,
      impressum: false,
      contactsShow: true
    }
  },
  methods: {
    impressumClick(val) {
      if (val) {
        this.contactsShow = false
        setTimeout(() => { this.impressum = true }, 500)
      } else {
        this.impressum = false
        setTimeout(() => {
          this.contactsShow = true
        }, 500)
      }
    }
  },
  mounted() {
    let el = this.$root.$el.querySelector('.contact-container')
    el.style.maxHeight = 'none'
  }
}
</script>
<style lang="scss">
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity .5s;
}
.fade-leave-active {
  transition: opacity .4s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.slide-fade-enter-active {
  transition: all .5s ease;
}
.slide-fade-leave-active {
  transition: all .5s ease;
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateY(60px);
  opacity: 0;
}
.container-fluid {
//height: calc(100vh - 188px);
  overflow: hidden;
  min-height: 100%;
}

.contact-img {
  background: url("../assets/Contact-New.jpg") ;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.contact-container {
  display: grid;
  min-height: 100%;
  grid-template-columns: 1fr 1fr;
}
.calc {
  height:calc(100vh - 188px);
  @media (max-width: 600px) {
    height: auto;
  }
}
.description-calc {
  justify-content: start !important;
  height:calc(100vh - 188px);
}
.contact-description-text {
  text-align: justify;
  font-size: 15px;
  padding: 0 5rem;
  margin-bottom: 1rem;
}

.contact-description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #EEFAFB;
  padding: 70px 0 30px;

  .ksu-contact-link {
    font-family: raleway-med;
    font-size: 1.3em;
    color: black;

    &:hover {
      color: black;
    }
  }
}

@media (max-width: 600px) {
  .contact-container {
    grid-template-columns: 1fr!important;
  }
  .container-fluid {
    max-height: 100%;
  }
  .contact-img{
    height: 600px;
  }
}

</style>
